
import React, { useState, useRef, useEffect } from "react";
import JoditEditor from "jodit-react";

import axios, { AxiosRequestHeaders } from 'axios';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useNavigate } from 'react-router-dom';
const notifydata: any = {
  position: 'top-center',
  autoClose: 3000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "light",
}

const notify = () =>
  toast.success('Question deleted Successfully!', notifydata);
const notifyErr = () => toast.error('Question not added!', notifydata);

function QsAdd() {
  const questionEditorRef = useRef(null);
  const answer1EditorRef = useRef(null);
  const answer2EditorRef = useRef(null);
  const answer3EditorRef = useRef(null);
  const answer4EditorRef = useRef(null);
  const [question, setQuestion] = useState('');
  const [answer1, setAnswer1] = useState('');
  const [answer2, setAnswer2] = useState('');
  const [answer3, setAnswer3] = useState('');
  const [answer4, setAnswer4] = useState('');
  const [correctAnswer, setCorrectAnswer] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [allSubject, setAllSubject] = useState([]);
  const [subjectId, setSubjectId] = useState('');
  const navigate = useNavigate();

  const headers: AxiosRequestHeaders = {
    'x-access-token': localStorage.getItem('jwttoken') || '',
  };

  //  ===============
  const callApiFetchAllSubject = async () => {
    try {
      const url = process.env.REACT_APP_API_URL + 'subjects/readallsubject';
      const response = await axios.get(url);
      // const optionsArray = Object.values(response.data);
      // console.log(optionsArray);
      setAllSubject(response.data);
      console.log('Calling subject list', response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    callApiFetchAllSubject();
  }, []);

  // ===============
  const handlePostData = async () => {
    try {
      setLoading(true);
      setError(null);

      const url = process.env.REACT_APP_API_URL + 'questions/addquestion';
      const data = {
        subject_id: subjectId,
        question,
        answer1,
        answer2,
        answer3,
        answer4,
        correctanswer: correctAnswer,
      };

      const res = await axios.post(url, data);
      if (res.status === 201 || res.status === 200) {
        notify();
        navigate("/qslist")
        navigate('/qslist');
      } else {
        notifyErr();
      }

      setQuestion('');
      setAnswer1('');
      setAnswer2('');
      setAnswer3('');
      setAnswer4('');
      setCorrectAnswer('');
    } catch (err) {
      // console.error("Error posting data:", err);
      notifyErr();
      // setError("Failed to post data. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container mt-5">
      <div className="card">
        <div className="card-body">
          <div className="row mb-2">
            <div className="col-4">
              <label htmlFor="lastName" className="col-form-label">
                Subject:
              </label>
            </div>
            <div className="col-8">
              <select
                name="subject_id"
                className="form-control"
                value={subjectId}
                onChange={(e) => setSubjectId(e.target.value)} 
              >
                {allSubject &&
                  allSubject.map((subject: any) => (
                    <option
                      key={subject.subject_id}
                      value={subject.subject_id}
                    >
                      {subject.subject_name}
                    </option>
                  ))}
              </select>

            </div>
          </div>

          <div>
            <h2>Question</h2>
            <JoditEditor
              ref={questionEditorRef}
              value={question}
              onChange={(newContent) => setQuestion(newContent)}
            />
          </div>

          <div>
            <h3>Answer 1</h3>
            <JoditEditor
              ref={answer1EditorRef}
              value={answer1}
              onChange={(newContent) => setAnswer1(newContent)}
            />
          </div>

          <div>
            <h3>Answer 2</h3>
            <JoditEditor
              ref={answer2EditorRef}
              value={answer2}
              onChange={(newContent) => setAnswer2(newContent)}
            />
          </div>

          <div>
            <h3>Answer 3</h3>
            <JoditEditor
              ref={answer3EditorRef}
              value={answer3}
              onChange={(newContent) => setAnswer3(newContent)}
            />
          </div>

          <div>
            <h3>Answer 4</h3>
            <JoditEditor
              ref={answer4EditorRef}
              value={answer4}
              onChange={(newContent) => setAnswer4(newContent)}
            />
          </div>

          <div className="mb-5" />

          <div className="row mb-2">
            <div className="col-4">
              <label htmlFor="correctAnswer" className="col-form-label">
                Correct answer:
              </label>
            </div>
            <div className="col-8">
              <input
                id="correctAnswer"
                name="correctanswer"
                type="number"
                min="1"
                max="5"
                className="form-control"
                value={correctAnswer}
                onChange={(e) => setCorrectAnswer(e.target.value)}
              />
            </div>
          </div>

          <div className="mb-5" />

          <button
            className="btn btn-primary"
            onClick={handlePostData}
            disabled={loading}
          >
            {loading ? 'Posting...' : 'Post Data'}
          </button>

          {error && <p className="text-danger mt-3">{error}</p>}
        </div>
        <ToastContainer />
      </div>
    </div>
  );
}

export default QsAdd;


