import React, { FC, useState } from 'react';
import axios, { AxiosRequestHeaders } from "axios";
import { Formik, Field, Form } from "formik";
import { useNavigate } from 'react-router-dom';
import loadImage from '../../images/giphy.gif';

const UserAddAdmin = () => {
    const navigate = useNavigate()
    const [apistatus, setApistatus] = useState(false);
    const [loader, setLoader] = useState(false);
    const [error, setError] = useState(false);

    const formikInitialValues = {
        name: "",
        email: "",
        mobile: "",
        password: ""
    }
    const headers = {
        "x-access-token": localStorage.getItem("jwttoken") || "",
    };
    const callApiUserAdd = async (values) => {
        setLoader(true);
        const url = process.env.REACT_APP_API_URL + 'users/adduser';
        try {
            // const response = await fetch(url, {
            //     method: "POST",
            //     body: JSON.stringify(values),
            // });
            const response = await axios.post(url, values, { headers });
            // console.log(response);
            if (response.status === 201) {
                setApistatus(true);
                setLoader(false);
                navigate('/userslist')
            }
        }
        catch (error) {
            setLoader(false);
            setApistatus(false);
            if (error.response.status === 409) {
                setError('Email or mobile number already registered');
            }
            else {
                setError('Something went wrong !please try again later');
            }
        }
    }

    return (
        <div>

            <h4 className='bg-info bg-opacity-25 text-center py-2 mb-3'>Sign Up Form</h4>
            <section className="contact-section">
                <div className="container">

                    <div className="row">
                        <div className="col-12 text-center">
                            <h4 className="contact-title">Please, enroll candidate here for online exam.</h4>
                        </div>

                        <div className='col-12 bg-info bg-opacity-10 rounded mb-4'>

                            <Formik
                                initialValues={formikInitialValues}
                                onSubmit={async (values, { resetForm }) => {
                                    //console.log(values);
                                    const res = await callApiUserAdd(values);
                                    // if (!error) {
                                    //     resetForm();
                                    // };
                                }}
                            >
                                <Form>
                                    <br></br>
                                    <div className="form-group row">
                                        <label className="col-sm-4 col-form-label">Name</label>
                                        <div className='col-sm-8'>
                                            <Field name="name" type="text" className="form-control" placeholder="Enter Full Name" required />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label className="col-sm-4 col-form-label">Email</label>
                                        <div className='col-sm-8'>
                                            <Field name="email" type="email" className="form-control" placeholder="Enter Gmail" required />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label className="col-sm-4 col-form-label">Mobile</label>
                                        <div className='col-sm-8'>
                                            <Field name="mobile" type="text" className="form-control" placeholder="Enter Mobile No" required />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label className="col-sm-4 col-form-label">Password</label>
                                        <div className='col-sm-8'>
                                            <Field name="password" type="text" className="form-control" placeholder="Password from LMS Portal" />
                                        </div>
                                    </div>


                                    <br></br>
                                    <button type="submit" className='btn btn-success'>Register</button>

                                    <br></br>
                                    <br></br>

                                </Form>
                            </Formik>

                            {
                                apistatus && (
                                    <div className="alert alert-success" role="alert">
                                        You are successfully registered. Do not register multiple times. We will connect with you in 1-2 week.
                                    </div>
                                )
                            }

                            {
                                error && (
                                    <div className="alert alert-danger" role="alert">
                                        {error}
                                    </div>
                                )
                            }
                        </div>

                        {loader && (
                            <div className="loading">
                                <img src={loadImage} alt="Loader" />
                            </div>
                        )
                        }

                    </div>
                </div>
            </section>

            {/* {
        status && (
          <div className="alert alert-success" role="alert">
            You are registered. Do not register again. Your exam details will sent on registered email or whatsapp.
          </div>
        )
      } */}

        </div>
    )
}

export default UserAddAdmin
