import React, { FC, useState } from 'react';
import axios from 'axios';
import { Formik, Field, Form } from "formik";
import { useNavigate } from 'react-router-dom';
import loadImage from '../../images/giphy.gif';

const UserAdd = () => {

  const [apistatus, setApistatus] = useState(false);
    const [loader, setLoader] = useState(false);
    const [error, setError] = useState(false);

    const formikInitialValues = {
        name: "",
        email: "",
        mobile: "",
        // whatsapp_mobile: "",
        city: "",
        // hometown: "",
        college: "",
        // university: "",
        passing_year: "",
        branch: "",
        referredby: "",
        courseid: "techintern"
    }
    const registerCourse = async (values) => {
        setLoader(true);
        //console.log(values);
        const url = process.env.REACT_APP_LMS_API_URL + 'course/register';

        try {
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(values),
            });
            //const response = await axios.post(url, values);
            //console.log(response);

            if (response.status === 201) {
                setApistatus(true);
                setLoader(false);
                return {
                    error: false
                }
            }
            else {
                setError(true);
                setLoader(false);
                return {
                    error: true
                }
            }
        }
        catch (err) {
            setError(true);
            setLoader(false);
            console.log('Error In API Call - ', err)
        }
    }

  return (
    <div>

      <h4 className='bg-info bg-opacity-25 text-center py-2 mb-3'>Sign Up Form</h4>
      <section className="contact-section">
                <div className="container">

                    <div className="row">
                        <div className="col-12 text-center">
                            <h4 className="contact-title">Please, register here. We will connect with you in 1-2 weeks.</h4>
                        </div>

                        <div className='col-12 bg-info bg-opacity-10 rounded mb-4'>

                            <Formik
                                initialValues={formikInitialValues}
                                onSubmit={async (values, { resetForm }) => {
                                    //console.log(values);
                                    const { error } = await registerCourse(values);
                                    if (!error) {
                                        resetForm();
                                    };
                                }}
                            >
                                <Form>
                                    <br></br>
                                    <div className="form-group row">
                                        <label className="col-sm-4 col-form-label">Name</label>
                                        <div className='col-sm-8'>
                                            <Field name="name" type="text" className="form-control" placeholder="Enter Full Name" required />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label className="col-sm-4 col-form-label">Email</label>
                                        <div className='col-sm-8'>
                                            <Field name="email" type="email" className="form-control" placeholder="Enter Gmail" required />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label className="col-sm-4 col-form-label">Mobile</label>
                                        <div className='col-sm-8'>
                                            <Field name="mobile" type="text" className="form-control" placeholder="Enter Mobile No" required />
                                        </div>
                                    </div>

                                    {/* <div className="form-group row">
                                        <label className="col-sm-4 col-form-label">WhatsApp Mobile</label>
                                        <div className='col-sm-8'>
                                            <Field name="whatsapp_mobile" type="text" className="form-control" placeholder="Enter WhatsaApp No" />
                                        </div>
                                    </div> */}

                                    {/* <div className="form-group row">
                                        <label className="col-sm-4 col-form-label">Date of Birth</label>
                                        <div className='col-sm-8'>
                                            <Field name="dob" type="date" className="form-control" placeholder="" />
                                        </div>
                                    </div> */}

                                    <div className="form-group row">
                                        <label className="col-sm-4 col-form-label">Present Address</label>
                                        <div className='col-sm-8'>
                                            <Field name="city" type="text" className="form-control" placeholder="Enter Present Address" required />
                                        </div>
                                    </div>

                                    {/* <div className="form-group row">
                                        <label className="col-sm-4 col-form-label">Permanent Address</label>
                                        <div className='col-sm-8'>
                                            <Field name="hometown" type="text" className="form-control" placeholder="Enter Permanent Address" />
                                        </div>
                                    </div> */}

                                    <div className="form-group row">
                                        <label className="col-sm-4 col-form-label">College</label>
                                        <div className='col-sm-8'>
                                            <Field name="college" type="text" className="form-control" placeholder="College Name" required />
                                        </div>
                                    </div>

                                    {/* <div className="form-group row">
                                        <label className="col-sm-4 col-form-label">University</label>
                                        <div className='col-sm-8'>
                                            <Field name="university" type="text" className="form-control" placeholder="University Name" />
                                        </div>
                                    </div> */}

                                    <div className="form-group row">
                                        <label className="col-sm-4 col-form-label">Passing Year</label>
                                        <div className='col-sm-8'>
                                            <Field name="passing_year" type="text" className="form-control" placeholder="College Passing Year" required />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label className="col-sm-4 col-form-label">Branch / Stream</label>
                                        <div className='col-sm-8'>
                                            <Field name="branch" type="text" className="form-control" Enter placeholder="Branch/Stream like B Tech/BCA/MCA/M Tech and Branch" required />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label className="col-sm-4 col-form-label">Where Did You Hear About Us</label>
                                        <div className='col-sm-8'>
                                            <Field name="referredby" type="text" className="form-control" placeholder="TPO/LinkedIn/Instagram/WhatsApp/Others" />
                                        </div>
                                    </div>  

                                    <Field name="courseid" type="hidden" className="form-control" placeholder="Enter Full Name" required />                                  

                                    <br></br>
                                    <button type="submit" className='btn btn-success'>Register</button>

                                    <br></br>
                                    <br></br>
                                    <p>Note - We will connect in 1-2 weeks regarding further process. Do not register multiple times.</p>
                                    <br></br>
                                </Form>
                            </Formik>

                            {
                                apistatus && (
                                    <div className="alert alert-success" role="alert">
                                        You are successfully registered. Do not register multiple times. We will connect with you in 1-2 week.
                                    </div>
                                )
                            }

                            {
                                error && (
                                    <div className="alert alert-danger" role="alert">
                                        Server Error. Please, try after sometime.
                                    </div>
                                )
                            }
                        </div>

                        {loader && (
                            <div className="loading">
                                <img src={loadImage} alt="Loader" />
                            </div>
                        )
                        }

                    </div>
                </div>
            </section>

      {/* {
        status && (
          <div className="alert alert-success" role="alert">
            You are registered. Do not register again. Your exam details will sent on registered email or whatsapp.
          </div>
        )
      } */}

    </div>
  )
}

export default UserAdd
