import axios, { AxiosRequestHeaders } from 'axios';
import { Field, Form, Formik ,ErrorMessage} from 'formik';
import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './ExamsAdd.css';

const ExamsAdd: FC = () => {
  const examDataIntialState = {
    examname: '',
    subject_id: '',
    duration: '',
    numOfQue: '',
    marksCorrect: '',
    marksIncorrect: '',
    launchdate: '',
    launchtime: '',
    endDate: '',
    endTime: '',
  };
  const navigate = useNavigate();
  const todayDate =
    new Date().toLocaleString('default', { year: 'numeric' }) +
    '-' +
    new Date().toLocaleString('default', { month: '2-digit' }) +
    '-' +
    new Date().toLocaleString('default', { day: '2-digit' });
  const todayTime =
    new Date().toLocaleTimeString('default', { hour: '2-digit' }) +
    ':' +
    new Date().toLocaleTimeString('default', { minute: '2-digit' });

  const headers: AxiosRequestHeaders = {
    'x-access-token': localStorage.getItem('jwttoken') || '',
  };

  //adding subject api fetch
  const [subject, setSubject] = useState<any[]>([]);

  const callApiSubjectsList = async () => {
    try {
      const url = process.env.REACT_APP_API_URL + 'subjects/readallsubject';
      const response = await axios.get(url, { headers });
      //console.log(response);
      setSubject(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    callApiSubjectsList();
  }, []);

  const callApiExamAdd = async (values: any) => {
    const url = process.env.REACT_APP_API_URL + 'exams/createexam';
    const response = await axios.post(url, values, { headers });
    //console.log(response);
    navigate('/examslist');
  };

  const validateForm = (values: any) => {
    let errors: { [key: string]: string } = {}; 
    
    const requiredFields : { [key: string]: string }  = {
      examname: 'Exam name is required*',
      subject_id: 'Subject is required*',
      duration: 'Duration is required*',
      numOfQue: 'Number of questions is required*',
      marksCorrect: 'Marks for correct answer is required*',
      marksIncorrect: 'Marks for incorrect answer is required*',
      launchdate: 'Launch date is required*',
      launchtime: 'Launch time is required*',
      endDate: 'End date is required*',
      endTime: 'End time is required*',
    };
    // Check each required field and set error message if empty
    for (const field in requiredFields) {
      if (!values[field]) {
        errors[field] = requiredFields[field];
      }
    }
    return errors;
  };

  return (
    <>
      <h4 className="bg-info bg-opacity-25 text-center py-2 mb-3">
        Create New Exam
      </h4>
      <div className="row">
        <div className="col-xs-12 col-md-12">
          <br></br>
          <Formik
            enableReinitialize={true}
            initialValues={examDataIntialState}
            validate={validateForm}
            onSubmit={async (values) => {
              // await new Promise((resolve) => setTimeout(resolve, 500));
              // alert(JSON.stringify(values, null, 2));
              // console.log(values);
              callApiExamAdd(values);
              alert('Exam added successfully');
            }}
          >
            <div className="form-container">
              <Form className="examAddForm">
                <div className="row">
                  <ErrorMessage name="examname" component="p" className="error" /> 
                  <label htmlFor="lastName" className='flex'>Exam Name :</label>
                  <Field name="examname" type="text" max="100" />
                </div>

                <div className="row">
                    <ErrorMessage name="subject_id" component="div" className="error" />
                  <label htmlFor="lastname">Subject Name :</label>
                  <Field name="subject_id" as="select" className="my-subject">
                    {subject.map((subjects) => (
                      <option
                        key={subjects.subject_id}
                        value={subjects.subject_id}
                      >
                        {subjects.subject_name}
                      </option>
                    ))}
                  </Field>
                </div>
                <div className="row">
                  <ErrorMessage name="duration" component="div" className="error"  /> 
                  <label htmlFor="lastName">Duration(in minutes) :</label>
                  <Field name="duration" type="number" />
                </div>
                <div className="row">
                <ErrorMessage name="numOfQue" component="div" className="error" />
                  <label htmlFor="lastName">Number of Questions :</label>
                  <Field name="numOfQue" type="number" />
                </div>
                <div className="row">
                <ErrorMessage name="marksCorrect" component="div" className="error" />
                  <label htmlFor="lastName">Marks for Correct Answer :</label>
                  <Field name="marksCorrect" type="number" />
                </div>
                <div className="row">
                <ErrorMessage name="marksIncorrect" component="div" className="error" />
                  <label htmlFor="lastName">Marks for Incorrect Answer :</label>
                  <Field name="marksIncorrect" type="number" />
                </div>
                <div className="row">
                <ErrorMessage name="launchdate" component="div" className="error" />
                  <label htmlFor="lastName">Launch Date :</label>
                  <Field name="launchdate" type="date" min={todayDate} />
                </div>
                <div className="row">
                <ErrorMessage name="launchdate" component="div" className="error" />
                  <label htmlFor="lastName">Launch Time :</label>
                  <Field name="launchtime" type="time" min={todayTime} />
                </div>
                  
                <div className="row">
                <ErrorMessage name="endDate" component="div" className="error" />
                  <label htmlFor="lastName">End Date :</label>
                  <Field name="endDate" type="date" min={todayDate} />
                </div>
                <div className="row">
                <ErrorMessage name="launchdate" component="div" className="error" />
                  <label htmlFor="lastName">End Time :</label>
                  <Field name="endTime" type="time" min={todayTime} />
                </div>

                <div className="row">
                  <button type="submit" className="btn btn-primary">
                    Create New Exam
                  </button>
                </div>
              </Form>
            </div>
          </Formik>
        </div>
      </div>
    </>
  );
};

export default ExamsAdd;
