import React, { FC, useEffect, useRef, useState } from 'react';
import axios, { AxiosRequestHeaders } from 'axios';
import { Formik, Field, Form } from "formik";
import JoditEditor from "jodit-react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, useParams } from 'react-router-dom';
import loaderimage from '../../images/giphy.gif';
const _ = require('lodash');

const notifydata: any = {
  position: 'top-center',
  autoClose:3000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "light",
}

const notify = () =>{
  toast.success('Question Updated',notifydata);
}
const notifyErr = () =>{
  toast.error('Question not Updated',notifydata);
}
const QsEdit: FC = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  
  const questionEditorRef = useRef(null);
  const answer1EditorRef = useRef(null);
  const answer2EditorRef = useRef(null);
  const answer3EditorRef = useRef(null);
  const answer4EditorRef = useRef(null);

  const [question , setQuestion] = useState('');
  const [qs_id , setQs_id] = useState();
  const [answer1 , setAnswer1] = useState('');
  const [answer2 , setAnswer2] = useState('');
  const [answer3 , setAnswer3] = useState('');
  const [answer4 , setAnswer4] = useState('');
  const [correctAnswer, setCorrectAnswer] = useState('');
  const [error, setError] = useState(null);
  const [allSubject, setAllSubject] = useState([]);
  const [subjectId, setSubjectId] = useState('');

  const editorConfig = {
    disablePlugins: ['placeholder'],
  };

  const headers: AxiosRequestHeaders = {
    'x-access-token': localStorage.getItem('jwttoken') || '',
  };
  const callApiQsEdit = async () => {
    try {
      setLoading(true);
      setError(null);

      const url = process.env.REACT_APP_API_URL + 'questions/editquestion';
      const data = {
        subject_id: subjectId,
        question,
        answer1,
        answer2,
        answer3,
        answer4,
        correctanswer: correctAnswer,
        qs_id
      };

      const res = await axios.put(url, data,{headers});
      console.log("data for updating the question",data)
      if (res.status === 201 || res.status === 200) {
        notify();
        navigate("/qslist")
        navigate('/qslist');
      } else {
        notifyErr();
      }

    } catch (err) {
      console.error("Error updating data:", err);
      notifyErr();
    } finally {
      setLoading(false);
    }
  };

  const callApiQsDetails = async (id: any) => {
    setLoader(true)
    try {
      const url = process.env.REACT_APP_API_URL + 'questions/searchquestion/' + id;
      const response = await axios.get(url,{headers});
      console.log(response.data[0])
      const { answer1,answer2,answer3,answer4,correctanswer,qs_id,question,subject_id} = response.data[0];
          setQuestion(question);
          setAnswer1(answer1);
          setAnswer2(answer2);
          setAnswer3(answer3);
          setAnswer4(answer4);
          setCorrectAnswer(correctanswer);
          setQs_id(qs_id)
          setSubjectId(subject_id)
    } catch (error) {
        console.error(error)      
    }
    finally{
      setLoader(false)
    }
  }


  const callApiFetchAllSubject = async () => {
    try {
      const url = process.env.REACT_APP_API_URL + 'subjects/readallsubject';
      const response = await axios.get(url,{headers});
      setAllSubject(response.data);
    } catch (error) {
      console.log(error);
    }
  };


  useEffect(() => {
    callApiQsDetails(id)
    callApiFetchAllSubject();
  }, []);


  return (
    <>
      <div className="container mt-5">
      <div className="card">
        <div className="card-body">
          <div className="row mb-2">
            <div className="col-4">
              <label htmlFor="lastName" className="col-form-label">
                Subject:
              </label>
            </div>
            <div className="col-8">
              <select
                name="subject_id"
                className="form-control"
                value={subjectId}
                onChange={(e) => setSubjectId(e.target.value)} 
              >
                {allSubject &&
                  allSubject.map((subject: any) => (
                    <option
                      key={subject.subject_id}
                      value={subject.subject_id}
                    >
                      {subject.subject_name}
                    </option>
                  ))}
              </select>

            </div>
          </div>

          <div>
            <h2>Question</h2>
            <div className="container mt-5">
            

          </div>
            <JoditEditor
              ref={questionEditorRef}
              value={question}
              onChange={(newContent) => setQuestion(newContent)}
              config={editorConfig}
            />
          </div>

          <div>
            <h3>Answer 1</h3>
            <JoditEditor
              ref={answer1EditorRef}
              value={answer1}
              onChange={(newContent) => setAnswer1(newContent)}
              config={editorConfig}
            />
          </div>

          <div>
            <h3>Answer 2</h3>
            <JoditEditor
              ref={answer2EditorRef}
              value={answer2}
              onChange={(newContent) => setAnswer2(newContent)}
              config={editorConfig}
            />
          </div>

          <div>
            <h3>Answer 3</h3>
            <JoditEditor
              ref={answer3EditorRef}
              value={answer3}
              onChange={(newContent) => setAnswer3(newContent)}
              config={editorConfig}
            />
          </div>

          <div>
            <h3>Answer 4</h3>
            <JoditEditor
              ref={answer4EditorRef}
              value={answer4}
              onChange={(newContent) => setAnswer4(newContent)}
              config={editorConfig}
            />
          </div>

          <div className="mb-5" />

          <div className="row mb-2">
            <div className="col-4">
              <label htmlFor="correctAnswer" className="col-form-label">
                Correct answer:
              </label>
            </div>
            <div className="col-8">
              <input
                id="correctAnswer"
                name="correctanswer"
                type="number"
                min="1"
                max="5"
                className="form-control"
                value={correctAnswer}
                onChange={(e) => setCorrectAnswer(e.target.value)}
              />
            </div>
          </div>

          <div className="mb-5" />

          <button
            className="btn btn-primary"
            onClick={callApiQsEdit}
            disabled={loading}
          >
            {loading ? 'Updating' : 'Updated'}
          </button>

          {error && <p className="text-danger mt-3">{error}</p>}
        </div>
        <ToastContainer />
      </div>
    </div>

      {
        loader &&
        <div className='loader'>
          <img src={loaderimage} alt="Loader" />
        </div>
      }
    </>
  )
}

export default QsEdit

