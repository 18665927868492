import React, { FC, useEffect, useState } from 'react';
import axios, { AxiosRequestHeaders } from 'axios';
import { Formik, Field, Form } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import './ExamsAdd.css';

const _ = require('lodash');

const ExamsUpdate: FC = () => {
  const { examId } = useParams();
  const [subject, setSubject] = useState<any[]>([]);

  //console.log("exam id - ", examId);
  const examDataIntialState = {
    exam_id: examId,
    examname: '',
    subject_id: 1,
    duration: '',
    numOfQue: '',
    marksCorrect: '',
    marksIncorrect: '',
    launchdate: '',
    launchtime: '',
    endDate: '',
    endTime: '',
  };
  const [updateData, SetUpdateData] = useState(examDataIntialState);
  const navigate = useNavigate();
  // const todayDate =
  //   new Date().toLocaleString('default', { year: 'numeric' }) +
  //   '-' +
  //   new Date().toLocaleString('default', { month: '2-digit' }) +
  //   '-' +
  //   new Date().toLocaleString('default', { day: '2-digit' });
  // const todayTime =
  //   new Date().toLocaleTimeString('default', { hour: '2-digit' }) +
  //   ':' +
  //   new Date().toLocaleTimeString('default', { minute: '2-digit' });

  const headers: AxiosRequestHeaders = {
    'x-access-token': localStorage.getItem('jwttoken') || '',
  };
  const callexamUpdateApi = async (data: any) => {
    // Needs to be changed
    const url = process.env.REACT_APP_API_URL + 'exams/updateexam';
    // console.log('Calling update exam', data);
    const response = await axios.put(url, data, { headers });
  };

  const getExambyIdApi = async (id: any) => {
    // Needs to be changed
    const url = process.env.REACT_APP_API_URL + 'exams/fetchexam/' + id;
    const response = await axios.get(url, { headers });
    // console.log(response);
    return response.data;
  };
  const callApiSubjectsList = async () => {
    try {
      const url = process.env.REACT_APP_API_URL + 'subjects/readallsubject';
      const response = await axios.get(url, { headers });
      //console.log(response);
      setSubject(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    callApiSubjectsList();
  }, []);

  useEffect(() => {
    (async () => {
      // To be used when api provided
      let data = await getExambyIdApi(examId);
      let respData = data[0];
      respData['launchdate'] = respData?.launchdate?.split('T')[0];
      respData['endDate'] = respData?.endDate?.split('T')[0];
      SetUpdateData(data[0]);
      // console.log('Updating examByID', data);
    })();
  }, []);

  return (
    <>
      <h4 className="bg-info bg-opacity-25 text-center py-2 mb-3">
        Update Exam Details
      </h4>
      {/* <p>Fields to be updated...</p> */}
      <Formik
        enableReinitialize={true}
        initialValues={updateData}
        onSubmit={async (values) => {
          // await new Promise((resolve) => setTimeout(resolve, 500));
          // alert(JSON.stringify(values, null, 2));
          //console.log(values);
          if (_.isEqual(values, updateData)) {
            alert('No Updates made');
          } else {
            // eslint-disable-next-line no-restricted-globals
            var val = confirm('Sure you want to update data?');
            if (val === true) {
              // values['exam_id'] = examId;
              await callexamUpdateApi(values);
              alert('Updated successfully');
              navigate('/examslist');
            } else {
              alert('Stopped Updating');
            }
          }
        }}
      >
        <div className="form-container">
          <Form className="examAddForm">
            <Field hidden type="number" name="exam_id"></Field>

            <div className="row">
              <label htmlFor="lastName">Exam Name :</label>
              <Field name="examname" type="text" max="100" />
            </div>
            <div className="row">
              <label htmlFor="subjectName">Subject Name :</label>
              <Field name="subject_id" as="select" className="my-subject">
                {subject.map((subjects) => (
                  <option
                    key={subjects.subject_id}
                    value={subjects.subject_id}
                    // selected={subjects.subject_id === '20'}
                  >
                    {subjects.subject_name}
                  </option>
                ))}
              </Field>
            </div>

            <div className="row">
              <label htmlFor="lastName">Duration(in minutes) :</label>
              <Field name="duration" type="number" />
            </div>

            <div className="row">
              <label htmlFor="lastName">Number of Questions :</label>
              <Field name="numOfQue" type="number" />
            </div>

            <div className="row">
              <label htmlFor="lastName">Marks for Correct Answer :</label>
              <Field name="marksCorrect" type="number" />
            </div>

            <div className="row">
              <label htmlFor="lastName">Marks for Incorrect Answer :</label>
              <Field name="marksIncorrect" type="number" />
            </div>

            <div className="row">
              <label htmlFor="lastName">Launch Date :</label>
              <Field name="launchdate" type="date" /*min={todayDate}*/ />
            </div>

            <div className="row">
              <label htmlFor="lastName">Launch Time :</label>
              <Field name="launchtime" type="time" /*min={todayTime}*/ />
            </div>

            <div className="row">
              <label htmlFor="lastName">End Date :</label>
              <Field name="endDate" type="date" /*min={todayDate}*/ />
            </div>

            <div className="row">
              <label htmlFor="lastName">End Time :</label>
              <Field name="endTime" type="time" /*min={todayTime}*/ />
            </div>

            <div className="row">
              <button type="submit" className="btn btn-primary">
                Update Exam
              </button>
            </div>
          </Form>
        </div>
      </Formik>
    </>
  );
};

export default ExamsUpdate;
