import React, { FC, useEffect, useState } from 'react';
import axios, { AxiosRequestHeaders } from 'axios';
import { Formik, Field, Form } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import './SubjectAdd.css';

const _ = require('lodash');

const SubjectUpdate: FC = () => {
  const { id } = useParams();
  // console.log('subject id - ', id);
  const headers: AxiosRequestHeaders = {
    'x-access-token': localStorage.getItem('jwttoken') || '',
  };
  const subjectDataIntialState = {
    subject_id: id,
    subjectname: '',
    // subjectAuthorName: '',
    // subjectUpdateDate: '',
    // subjectUpdateTime: '',
  };
  const [updateData, setUpdateData] = useState(subjectDataIntialState);
  const navigate = useNavigate();
  // const todayDate =
  //   new Date().toLocaleString('default', { year: 'numeric' }) +
  //   '-' +
  //   new Date().toLocaleString('default', { month: '2-digit' }) +
  //   '-' +
  //   new Date().toLocaleString('default', { day: '2-digit' });
  // const todayTime =
  //   new Date().toLocaleTimeString('default', { hour: '2-digit' }) +
  //   ':' +
  //   new Date().toLocaleTimeString('default', { minute: '2-digit' }) +
  //   ':' +
  //   new Date().toLocaleTimeString('default', { second: '2-digit' });

  const callsubejctupdateApi = async (values: any) => {
    const url = process.env.REACT_APP_API_URL + 'subjects/updatesubject';
    const response = await axios.post(url, values, { headers });
    //console.log('Updating Subject', response);
  };
  const getSubjectbyIdApi = async (id: any) => {
    const url = process.env.REACT_APP_API_URL + 'subjects/subject/' + id;
    const response = await axios.get(url, { headers });
    // console.log(response);
    return response.data;
  };
  useEffect(() => {
    (async () => {
      let data = await getSubjectbyIdApi(id);
      setUpdateData(data[0]);
      //console.log('calling getsubjectAPI', data);
    })();
  }, []);

  // useEffect(() => {
  //   console.log('updateData', updateData);
  // })
  return (
    <>
      <h4 className="bg-info bg-opacity-25 text-center py-2 mb-3">
        Update subject details
      </h4>
      <Formik
        enableReinitialize={true}
        initialValues={updateData}
        onSubmit={async (values) => {
          if (_.isEqual(values, updateData)) {
            alert('No update made');
          } else {
            //eslint-disable-next-line no-restricted-globals
            var val = confirm('Sure you want to update data?');
            if (val === true) {
              await callsubejctupdateApi(values);
              alert('Update successfully');
              navigate('/subjectslist');
            } else {
              alert('Stopped updating');
            }
          }
        }}
      >
        <div className="form-container">
          <Form className="examAddForm">
            <div className="row">
              <label htmlFor="subject_name">Subject Name :</label>
              <Field name="subject_name" type="text" max="100" />
            </div>

            <div className="row">
              <Field name="subject_id" type="hidden" max="100" />
            </div>

            <div className="row">
              <Field name="status" type="hidden" max="100" />
            </div>

            {/* <div className="row">
              <label htmlFor="subject_id">Subject I.D.</label>
              <Field name="subjectID" type="text" max="100" />
            </div> */}

            {/* <div className="row">
              <label htmlFor="subjectAuthorName">Author Name :</label>
              <Field name="subjectAuthorName" type="text" max="100" />
            </div> */}

            {/* <div className="row">
              <label htmlFor="subjectUploadDate">Subject Update date :</label>
              <Field name="subjectUpdateDate" type="date" min={todayDate} />
            </div> */}

            {/* <div className="row">
              <label htmlFor="subjectUploadTime">Subject Update Time :</label>
              <Field name="subjectUpdateTime" type="time" min={todayTime} />
            </div> */}

            <div className="row">
              <button type="submit" className="btn btn-primary">
                Update Subject
              </button>
              {}
            </div>
          </Form>
        </div>
      </Formik>
    </>
  );
};
export default SubjectUpdate;
