import React from 'react';
import { Link } from 'react-router-dom';

const candidates = [
    { id: 1, name: 'Candidate 1', totalMarks: 100, totalQuestions: 20, totalAnswered: 15, totalCorrect: 12, totalIncorrect: 3 },
    { id: 2, name: 'Candidate 2', totalMarks: 100, totalQuestions: 20, totalAnswered: 18, totalCorrect: 15, totalIncorrect: 3 },
    { id: 3, name: 'Candidate 3', totalMarks: 100, totalQuestions: 20, totalAnswered: 16, totalCorrect: 14, totalIncorrect: 2 },
    // Add more candidates as needed
];

const ResultList = () => {
    return (
        <>
            <div className='row'>
                <div className='col-12'>
                <h2 id='resultlist'>Results List</h2>
                <table className="table table-bordered">
                    <thead>
                        <tr>
                            <th>Candidate</th>
                            <th>Total Marks</th>
                            <th>Total Num of Questions</th>
                            <th>Total Answered</th>
                            <th>Total Unanswered</th>
                            <th>Total Correct</th>
                            <th>Total Incorrect</th>
                        </tr>
                    </thead>
                    <tbody>
                        {candidates.map(candidate => (
                            <tr key={candidate.id}>
                                <td>{candidate.name}</td>
                                <td>{candidate.totalMarks}</td>
                                <td>{candidate.totalQuestions}</td>
                                <td>{candidate.totalAnswered}</td>
                                <td>{candidate.totalQuestions - candidate.totalAnswered}</td>
                                <td>{candidate.totalCorrect}</td>
                                <td>{candidate.totalIncorrect}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            </div>
            <div className="row">
        <div className="col-sm-12 col-lg-12">
          <Link to='/dashboard' className='link-success'>
            <div className="card text-white bg-flat-color-1">
              <div className="card-body">
                <div className="card-left pt-1 float-left">
                  <h3 className="mb-0 fw-r">
                    <span className="currency float-left mr-1">Dashboard</span>
                  </h3>
                </div>
              </div>
            </div>
          </Link>
        </div>
      </div>
    </>

  );
};

export default ResultList;